<template>
  <div v-if="loading">
    <b-alert show variant="info"><icon-text icon="spinner" spin>Loading...</icon-text></b-alert>
  </div>
  <div v-else-if="details">
    <b-card title="Guest WiFi Login" :sub-title="details.tenant">
    </b-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: true,
    details: null
  }),
  methods: {
    reload () {
      this.loading = true

      this.details = {
        tenant: 'Huntingtower'
      }

      this.loading = false
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
